
@keyframes slideInFromRight {
    0% {
        opacity: 0;
        transform: translateX(500%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

html.DarkMode {
    background: #303030 !important;
}

.App {
    background-color: #ffffff;

    #main-content {
        position: relative;
    }

    .floating-buttons-container .md-btn--floating {
        background: #2196f3;
    }

    .md-drawer {
        header {
            overflow: hidden;
        }

        .md-toolbar ~ .md-list--drawer {
            .developer-logo-container {
                position: relative;

                .developer-logo {
                    display: block;
                    width: 30%;
                    margin: 20px 20px;
                    cursor: pointer;
                }
            }
        }

        @media screen and (min-width: 320px) {
            header {
                height: 72px;
            }
    
            .md-toolbar ~ .md-list--drawer {
                height: calc(100% - 72px);
            }    
        }

        @media screen and (min-width: 764px) {
            header {
                height: 64px;
            }
    
            .md-toolbar ~ .md-list--drawer {
                height: calc(100% - 64px);
            }
        }

        @media screen and (min-width: 1025px) {
            .md-toolbar ~ .md-list--drawer {
                .developer-logo-container {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }

        }

        h2 {
            margin: 0;

            img.main-logo-img {
                display: block;
                width: calc(100% - 20px);
                margin: 12px 10px 0 10px;
            }

            small {
                display: block;
                font-size: 12px;
                line-height: 20px;
                text-align: right;
                margin: 0 15px 10px;
            }
        }


        @media screen and (max-width: 1024px) {
            h2 {

                img.main-logo-img {
                    margin: 18px 10px 0 10px;
                }
            } 
        }

    }

    #mainloading.md-progress.md-progress--linear {
        position: fixed;
        margin-top:3px;
        background-color: #ffeb3b;

        .md-progress--linear-active {
            background-color: #ff9800;
        }
    }

    .md-progress.md-progress--linear {
        background-color: #ffeb3b;

        .md-progress--linear-active {
            background-color: #ff9800;
        }
    }

    .switch-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

        > div {
            position: absolute;
            width: 100%;
            // height: -webkit-fill-available;
            height: 100% !important;
        }  
    }

    .AppPage {
        // padding-bottom: 60px;

        > .helpText {
            width: 100%;
            margin: 20px;
            padding: 10px;
            color: #555;
            text-align: center;
            background: #fafafa;
        }
    }

    .floating-buttons-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
        bottom: 10px;
        right: 10px;
        animation: 2s ease-out 0s 1 slideInFromRight;

        > button,
        > a[type=button] {
            display: block;
            margin-top: 10px;
            margin-left: auto;
        }
    }

    .ObjectEditor {
        .md-text-field-container,
        .md-menu-container.inline-block {
            padding: 0 20px;
        }

        .FileInputContainer {
            .md-file-input-container {
                margin-left: 20px;
            }
        }
    }

    .bg-color-danger {
        background-color: #b71c1c;
    }

    .ObjectMiniItem {
        margin-right: 10px;
        margin-top: 5px;
    }

    .ObjectViewer {
        > .md-list-item {
            display: inline-block;
        }
    }

    .ObjectViewerAndEditor {
        > .md-list-item {
            display: inline-block;
            vertical-align: top;

            > .md-fake-btn {
                cursor: default;
            }
        }

        > .md-list-item.models-field > .md-fake-btn {
            height: auto;
            min-height: 60px;
        }

        .EditorField {
            padding-left: 16px;
            padding-right: 16px;
        }

    }

    .md-list-item.multiline-text-field .md-text {
        height: auto;
        margin: 5px 0;

        .md-tile-text--primary {
            white-space: pre-wrap;
        }

        .md-text--secondary {
            white-space: pre-wrap;
        }
    }

    .md-list--drawer .md-list-item.active {
        background: rgba(0,0,0,.1);
    }

    .MiniCollectionView.flatBorder {
        border-top: solid 1px #dedede;
    }

    .ObjectTypeLegend.flatBorder {
        border-top: solid 1px #dedede;
    }

    .ObjectTypeLegend {
        margin-top: 60px;
        margin-bottom: 60px;
        text-align: center;

        .LegendList {

            .md-chip {
                background: none;
                color: rgba(0, 0, 0, 0.555);
                padding-left: 30px;
                padding-right: 14px;
            }

            .md-chip:focus {
                box-shadow: none;
            }

            .md-chip-text {
                color: rgba(0, 0, 0, 0.555);
            }

        }
    }

    .HelpView {
        display: flex;
        max-width: 500px;
        margin: 40px auto;
        // background: rgba(255, 255, 255, 0.99);
        padding: 10px;
        border-radius: 3px;
        // box-shadow: 0px 0px 4px #000;
        color: rgba(0, 0, 0, 0.88);
        opacity: .44;
        transition: opacity .5s;

        .HelpHeader {
            margin-right: 10px;

            .md-icon {
                color: rgba(0, 0, 0, 0.677);
                font-size: 40px;
            }
        }
    }

    .HelpView:hover {
        opacity: 1;
    }

    .CenteredActionButtons {
        flex-direction: column;

        > button {
            width: 100%;
            margin-top: 20px;
        }
    }

    .object-detail-container.md-card {
        position: relative;

        hr {
            border: none;
            border-top: solid 1px #8888;
        }

        .star-container {
            position: absolute;
            top: 5px;
            right: 5px;

            .star-btn-gold {
                color: gold;
            }
        }

        .object-viewer-checkbox {
            span {
                color: rgba(0,0,0,.87);
            }

            .md-selection-control-toggle {
                padding-left: 0;
                padding-right: 0;
                width: 30px;
            }
        }

        .viewer-autoheight .md-fake-btn {
            height: auto;
        }

        .MiniCollectionView {
            margin: 0;
            margin-bottom: 20px;
            width: 100%;
        }
    }

    .ObjectPicker {
        .md-list--nested-1 .md-list-tile {
            padding-left: 16px;
        }

        .md-text-field-message-container {
            padding: 0 20px;
        }
    }

    .ObjectPicker.ObjectPicker--error {
        .md-floating-label {
            color: #f44336;
        }

        .md-divider {
            background: #f44336;
        }
    }

    .collections-search .ObjectPicker {
        width: 100%;
    }

    .ObjectPicker .progress-container {
        margin: 0 20px;
        margin-top: -10px;
        width: calc(100% - 40px);
        height: 3px;

        .md-progress {
            margin: 0;
        }
    }

    .ObjectPicker.base-collection-search {
        .progress-container {
            margin: 0;
            margin-top: -3px;
            width: 100%;
        }
    }

    .ObjectEditForm {
        .helpText {
            margin: 20px;
            padding: 10px;
            color: #555;
            background: #fafafa;
        }
    }

    .ObjectListItem.TaskItem--overdue {
        .md-text--secondary {
            color: #f44336;
        }
    }

    .toolbar-notification-badge {
        .md-badge {
            top: 5px;
        }
    }

    .md-list--drawer {
        .toolbar-notification-badge-icon {
            .md-badge {
                top: -15px;
                right: -15px;
            }
        }
    }

    .react-calendar-heatmap {
        width: 100%;
        max-height: 200px;

        .color-scale-1 { fill: #d6e685; }
        .color-scale-2 { fill: #8cc665; }
        .color-scale-3 { fill: #44a340; }
        .color-scale-4 { fill: #1e6823; }
    }

    a.Link {
        display: inline-flex;
        text-decoration: none;
    }

    .SystemMenuGroup {
        margin: 10px;
        
        > button,
        > a {
            margin-right: 16px;
        }

        .md-background--primary .toolbar-notification-badge-icon i {
            color: #fff;
        }
    }
}

.LoginForm {
    form {
        padding-left: 20px;
        padding-right: 20px;
    }

    .md-card-title--title,
    .md-card-title--title-block {
        width: 100%;
        text-align: center;

        .md-text--secondary {
            font-weight: 400;
            margin-top: 20px;
        }
    }


    img.main-logo-img {
        display: block;
        width: calc(100% - 10px);
        margin: 0 5px;
    }

}

.App.App--DarkMode,
.DarkMode .ObjectEditDialog--Content,
.DarkMode .ObjectFilterForm--Content {
    background-color: #303030;

    .md-btn.md-text--disabled {
        color: #aaaaaa;
    }

    .md-picker-content-container {
        background: #777;

        .md-clock-time-value {
            color: #555;
        }

        .md-picker-text--active .md-clock-time-value {
            color: #fff;
        }
    }

    .md-list--drawer,
    .md-list {
        background-color: rgb(66, 66, 66);
    }

    .md-text,
    .md-title--dialog,
    .md-title--toolbar,
    .md-list .md-subheader,
    .md-tile-content .md-tile-text--primary,
    .md-text--theme-primary {
        color: #fff;
    }
    
    .md-text::placeholder,
    .md-tile-content .md-tile-text--secondary,
    .md-text--secondary,
    p {
        color: rgba(255, 255, 255, 0.7);
    }

    .md-icon {
        color: rgba(255, 255, 255, 0.7);
    }

    .md-background,
    .md-background--card {
        background-color: rgb(66, 66, 66);
    }

    .md-navigation-drawer-content {
        background-color: #303030;
    }

    .ObjectTypeLegend {
        .LegendList {
            .md-chip {
                color: rgba(255, 255, 255, 0.444);
            }

            .md-chip-text {
                color: rgba(255, 255, 255, 0.444);
            }

        }
    }

    .HelpView {
        color: rgba(255,255,255,.777);

        .HelpHeader {
            .md-icon {
                color: rgba(255,255,255,.777);
            }
        }
    }

    
    .object-detail-container.md-card {
        .object-viewer-checkbox {
            span {
                color: rgba(255,255,255,.87);
            }
        }
    }

    .AppPage {
        > .helpText {
            background: #303030;
            color: #ffffff;
        }
    }


    .md-drawer {
        .md-toolbar ~ .md-list--drawer {
            .developer-logo-container {
                .developer-logo {
                    filter: invert(100%);
                }
            }
        }

        h2 {
            img.main-logo-img {
                filter: invert(100%);
            }
        }

    }


    .LoginForm {
        img.main-logo-img {
            filter: invert(100%);
        }

    }

}

.App.App--LoggedOut {
    > header,
    > nav,
    .AppPage {
        filter: blur(3px);
    }
}

.ObjectEditDialog--Content,
.ObjectFilterForm--Content {
    padding: 60px 20px 20px 20px;
}

.ObjectFilterForm--Content .md-list--nested-1 .ObjectListItem > .md-list-tile {
    padding-left: 0;
}

.ObjectEditor,
.ObjectViewerAndEditor {


    .md-list-item.multiline-text-field .md-btn.md-text {
        margin: 0;
    }

    .md-chip .md-icon {
        z-index: 0;
    }

    .ModelFieldContainer {
        position: relative;

        .InlineObjectEditForm button.ShowEditDialogButton {
            position: absolute;
            top: 20px;
            right: 10px;
        }
    }

    .ModelsFieldContainer {
        position: relative;

        .InlineObjectEditForm button.ShowEditDialogButton {
            position: absolute;
            top: 20px;
            right: 10px;
        }

        .ObjectFilterForm button.ShowFilterDialogButton {
            position: absolute;
            top: 20px;
            right: 50px;
        }
    }

    .inline-block {
        display: inline-block;

        .md-text-field-container {
            padding: 0;
        }
    }
}

.ObjectEditDialog--Content {
    .inline-block {
        padding-right: 20px;
    }
}

.DarkMode {
    .ObjectEditDialog--Content,
    .ObjectFilterForm--Content {
        background-color: #424242;
    }
}

.md-fake-btn.active {
    background-color: #e3f2fd;
}

#root > div > header > .md-title {
    font-weight: 400;
}

#root > div > nav > header > h2 {
    font-weight: 100;
}


.LoginPage {
    height: -webkit-fill-available;

    .LoginPageBackground {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1;

        background: url(/bg-login.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        filter: blur(6px);
    }

    .LoginForm {
        background: rgba(255,255,255,.7);
        z-index: 2;
        margin-top: 10%;

        .md-list {
            background: none;
        }
    }
}

.md-dialog {
    .LoginForm {
        box-shadow: none;
    }
}

.padding020 {
    padding: 0 20px;
}

@media screen and (max-width: 425px) {
    .App {
        .md-dialog-footer--card {
            flex-direction: column;

            .md-btn {
                display: block;
                width: 100%;
            }
        }

        .object-detail-container .md-card-title .md-card-title--title-block {
            max-width: calc(100% - 80px);
            overflow: hidden;
        }
    }
}

@media screen and (min-width: 600px) {
    .listItemContainer.LimitHeightDesktop {
        height: 250px;
        overflow-y: auto;        
    }
}
